var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-tabs',[_c('form-tab',{attrs:{"tab-errors":_vm.tabErrors,"name":"general","title":_vm.$t('labels.profile_details'),"to":{
            name: _vm.routes.edit,
            params: { userId: _vm.userId },
            query: { tab: 0 },
        },"exact":"","replace":_vm.inForm}}),(_vm.userId && (_vm.isSuperAdmin || _vm.isCompanyAdmin))?_c('form-tab',{attrs:{"name":"userAddresses","title":_vm.$t('labels.addresses'),"to":{
            name: _vm.routes.addressList,
            params: { userId: _vm.userId },
        }}}):_vm._e(),(_vm.balanceEnabled)?_c('form-tab',{attrs:{"name":"currentBalances","title":_vm.$t('labels.balances'),"to":{
            name: _vm.routes.balanceList,
            params: { userId: _vm.userId },
        }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }