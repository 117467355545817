<template>
    <form-tabs>
        <form-tab
            :tab-errors="tabErrors"
            name="general"
            :title="$t('labels.profile_details')"
            :to="{
                name: routes.edit,
                params: { userId },
                query: { tab: 0 },
            }"
            exact
            :replace="inForm"
        ></form-tab>
        <form-tab
            v-if="userId && (isSuperAdmin || isCompanyAdmin)"
            name="userAddresses"
            :title="$t('labels.addresses')"
            :to="{
                name: routes.addressList,
                params: { userId },
            }"
        ></form-tab>
        <form-tab
            v-if="balanceEnabled"
            name="currentBalances"
            :title="$t('labels.balances')"
            :to="{
                name: routes.balanceList,
                params: { userId },
            }"
        ></form-tab>
    </form-tabs>
</template>

<script>
import { mapGetters } from 'vuex'
import routeType from '@/router/routeType'
import routeInfoType from '@/store/type/routeInfoType'
import authType from '@/store/type/authType'
import FormTab from '@/components/form/FormTab'
import FormTabs from '@/components/form/FormTabs'

export default {
    components: { FormTab, FormTabs },
    props: {
        userId: {
            type: [String, Number],
            default: null,
        },
        tabErrors: {
            type: Object,
            default: () => ({}),
        },
        isUserBalanceEnabled: {
            type: Boolean,
            default: null,
        },
    },
    data() {
        return {
            routeType,
        }
    },
    computed: {
        ...mapGetters({
            userBalances: routeInfoType.getters.GET_USERS,
            isCompanyAdmin: authType.getters.IS_COMPANY_ADMIN,
            isSuperAdmin: authType.getters.IS_SUPER_ADMIN,
        }),
        inForm() {
            return (
                this.$route.matched[2]?.name === routeType.USER_EDIT ||
                this.$route.matched[1]?.name === routeType.USER_CREATE
            )
        },
        routes() {
            return {
                edit: this.userId ? routeType.USER_EDIT : routeType.USER_CREATE,
                addressList: routeType.USER_ADDRESS_LIST,
                balanceList: routeType.COMPANY_BALANCE_LIST,
            }
        },
        userBalance() {
            return this.userBalances[this.userId]
        },
        userAllowanceCount() {
            return (
                this.userBalance?.allowances?.length ||
                this.userBalance?.companyTeams?.length
            )
        },
        balanceEnabled() {
            return (
                this.isUserBalanceEnabled ??
                (this.userAllowanceCount && this.isSuperAdmin)
            )
        },
    },
    mounted() {
        if (
            this.isUserBalanceEnabled === null &&
            !this.userBalance?.allowances
        ) {
            this.$store.dispatch(routeInfoType.actions.GET_USER, this.userId)
        }
    },
}
</script>
